import { tap, timeout, catchError, takeUntil } from "rxjs/operators";
import { IWorkOrderType, StatusType } from "@elevatedsignals/amygoodman";
import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { EMPTY, combineLatest } from "rxjs";
import { Component, OnDestroy, Injector } from "@angular/core";
import { handleObservableError } from "app/shared/utils";
import { Globals } from "app/shared/modules/globals/globals.service";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import {
	ezGiMigration,
	generalInventoryEnabled,
} from "app/modules/dashboard/selectors/facility-settings.selector";
import { DevCycleService } from "app/devcycle/devcycle.service";
import { DevCycleKey } from "app/devcycle/devcycleKeys";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { TranslocoService } from "@jsverse/transloco";

import { GenericCreateComponent } from "../generic/generic-create.component";

@Component({
	selector: "work-order-type-create",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class WorkOrderTypeCreateComponent
	extends GenericCreateComponent<IWorkOrderType>
	implements OnDestroy
{
	public schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			name: {
				type: "string",
				title: "Name",
				title_translation_key: marker("word_name"),
				warning: "Warning: Work Order Type name already in use.",
				warning_translation_key: marker(
					"form_field_warning_work_order_type_name_already_in_use",
				),
			},
			archive_remaining_plants: {
				type: "boolean",
				widget: "checkbox",
				title: "Archive remaining plants in work order when it is closed.",
				title_translation_key: marker(
					"form_field_label_archive_remaining_plants_in_work_order_when_it_is_closed",
				),
				default: true,
			},
			show_processing_loss: {
				type: "boolean",
				widget: "checkbox",
				title: "Show processing loss.",
				title_translation_key: marker("form_field_label_show_processing_loss"),
				default: true,
			},
			processing_loss_inventory_product_ids: {
				type: "array",
				title: "Processing Loss Inventory Products",
				title_translation_key: marker(
					"form_field_label_processing_loss_inventory_products",
				),
				widget: "data-select",
				items: {
					type: "number",
					oneOf: [
						{
							result_type: "inventory_products",
							queryString: {
								showArchived: "false",
							},
						},
					],
				},
				visibleIf: {
					show_processing_loss: [true],
				},
			},
			add_processing_loss_warning: {
				type: "boolean",
				widget: "checkbox",
				title: "Add Processing Loss Warning",
				title_translation_key: marker(
					"form_field_label_add_processing_loss_warning",
				),
				default: false,
				visibleIf: {
					show_processing_loss: [true],
				},
			},
			processing_loss_warning_upper_bound: {
				type: "number",
				widget: "number",
				title: "Upper bound (%)",
				title_translation_key: marker("form_field_label_upper_bound"),
				visibleIf: {
					add_processing_loss_warning: [true],
				},
			},
			processing_loss_warning_lower_bound: {
				type: "number",
				widget: "number",
				title: "Lower bound (%)",
				title_translation_key: marker("form_field_label_lower_bound"),
				visibleIf: {
					add_processing_loss_warning: [true],
				},
			},
			use_remaining_input: {
				type: "boolean",
				widget: "checkbox",
				title: "Work Order Inputs default to Use Remaining.",
				title_translation_key: marker(
					"form_field_label_work_order_inputs_default_to_use_remaining",
				),
				default: false,
			},
			use_work_order_location_output: {
				type: "boolean",
				widget: "checkbox",
				title: "Work Order Outputs default to Work Order Location.",
				default: false,
			},
			use_existing_inventory_output: {
				type: "boolean",
				widget: "checkbox",
				title: "Work Order Outputs +1 default to Use Existing Inventory.",
				title_translation_key: marker(
					"form_field_label_work_order_outputs_plus_one_default_to_use_existing_inventory",
				),
				description: `If unchecked, it will default to Create New Inventory.`,
				description_translation_key: marker(
					"form_field_description_if_unchecked_it_will_default_to_create_new_inventory",
				),
				default: false,
			},
			use_existing_lot_output: {
				type: "boolean",
				widget: "checkbox",
				title: "Work Order Outputs +1 default to Use Existing Lot.",
				title_translation_key: marker(
					"form_field_label_work_order_outputs_plus_one_default_to_use_existing_lot",
				),
				description: `If unchecked, they will default to Create New Lot.`,
				description_translation_key: marker(
					"form_field_description_if_unchecked_they_will_default_to_create_new_lot",
				),
				default: false,
			},
			add_equipment_after_close: {
				type: "boolean",
				widget: "checkbox",
				title: "Add or Remove Equipment on Work Order after Close.",
				title_translation_key: marker(
					"form_field_label_add_or_remove_equipment_on_work_order_after_close",
				),
				default: false,
			},
			add_equipment_after_lock: {
				type: "boolean",
				widget: "checkbox",
				title: "Add or Remove Equipment on Work Order after Lock.",
				title_translation_key: marker(
					"form_field_label_add_or_remove_equipment_on_work_order_after_lock",
				),
				default: false,
			},
			add_record_after_close: {
				type: "boolean",
				widget: "checkbox",
				title: "Add or Remove Record on Work Order after Close.",
				title_translation_key: marker(
					"form_field_label_add_or_remove_record_on_work_order_after_close",
				),
				default: false,
			},
			add_record_after_lock: {
				type: "boolean",
				widget: "checkbox",
				title: "Add or Remove Record on Work Order after Lock.",
				title_translation_key: marker(
					"form_field_label_add_or_remove_record_on_work_order_after_lock",
				),
				default: false,
			},
			require_lot_for_destruction: {
				type: "boolean",
				widget: "checkbox",
				title: "Require Lot for Work Order Destruction.",
				title_translation_key: marker(
					"form_field_require_lot_on_work_order_destruction",
				),
				default: false,
			},
			duplicate_timestamp: {
				type: "boolean",
				widget: "checkbox",
				title: "Work Order timestamp is duplicated on +1.",
				description: `If unchecked, timestamp will not be duplicated.`,
				default: false,
			},
			default_printer_job_template_id: {
				type: "number",
				widget: "data-select",
				title: "Default Printer Job Template",
				title_translation_key: marker(
					"form_field_label_default_printer_job_template",
				),
				oneOf: [
					{
						result_type: "printer_job_templates",
					},
				],
			},
			default_form_schema_uuids: {
				type: "array",
				widget: "data-select",
				title: "Related Records",
				title_translation_key: marker("form_field_label_default_form_schemas"),
				quick_create: false,
				items: {
					type: "string",
					oneOf: [
						{
							result_type: "schemas",
							value_key: "type",
							queryString: {
								recordFilter: "RELEASED",
							},
						},
					],
				},
			},
			record_pdfs_follow_related_record_order: {
				type: "boolean",
				widget: "checkbox",
				title: "Record PDFs follow Related Record Order",
				default: false,
				title_translation_key: marker(
					"form_field_label_record_pdfs_follow_related_record_order",
				),
				info_text: this._translocoService.translate(
					"form_field_info_record_pdfs_follow_related_record_order",
				),
			},
			required_form_schema_uuids: {
				type: "array",
				widget: "data-select",
				title: "Required Records",
				title_translation_key: marker("form_field_label_required_form_schemas"),
				quick_create: false,
				items: {
					type: "string",
					oneOf: [
						{
							result_type: "schemas",
							value_key: "type",
							queryString: {
								recordFilter: "RELEASED",
							},
						},
					],
				},
			},
			related_equipment_ids: {
				type: "array",
				widget: "data-select",
				title: "Related Equipment",
				title_translation_key: marker("form_field_label_related_equipment"),
				quick_create: false,
				items: {
					type: "number",
					oneOf: [
						{
							result_type: "equipments",
						},
					],
				},
			},
			required_equipment_ids: {
				type: "array",
				widget: "data-select",
				title: "Required Equipment",
				title_translation_key: marker("form_field_label_required_equipment"),
				quick_create: false,
				items: {
					type: "number",
					oneOf: [
						{
							result_type: "equipments",
						},
					],
				},
			},
			inventory_products_sources_outputs_section: {
				type: "string",
				widget: "section",
				title: "Source/Outputs Related to Work Orders",
				title_translation_key: marker(
					"form_field_label_source_outputs_related_to_work_orders",
				),
			},
			inventory_category_source_ids: {
				type: "array",
				title: "Allowed Inventory Categories for Inputs",
				title_translation_key: marker(
					"form_field_label_allowed_inventory_categories_for_inputs",
				),
				widget: "data-select",
				items: {
					type: "number",
					oneOf: [
						{
							result_type: "inventory_categories",
							queryString: {
								showArchived: "false",
							},
						},
					],
				},
			},
			inventory_category_output_ids: {
				type: "array",
				title: "Allowed Inventory Categories for Outputs",
				title_translation_key: marker(
					"form_field_label_allowed_inventory_categories_for_outputs",
				),
				widget: "data-select",
				items: {
					type: "number",
					oneOf: [
						{
							result_type: "inventory_categories",
							queryString: {
								showArchived: "false",
							},
						},
					],
				},
			},
			inventory_category_destruction_ids: {
				type: "array",
				title: "Allowed Inventory Categories for Destruction",
				title_translation_key: marker(
					"form_field_label_allowed_inventory_categories_for_destruction",
				),
				widget: "data-select",
				items: {
					type: "number",
					oneOf: [
						{
							result_type: "inventory_categories",
							queryString: {
								internal_vendor: "true",
								showArchived: "false",
							},
						},
					],
				},
			},
			inventory_product_source_ids: {
				type: "array",
				title: "Allowed Inventory Products for Inputs",
				title_translation_key: marker(
					"form_field_label_allowed_inventory_products_for_inputs",
				),
				widget: "data-select",
				items: {
					type: "number",
					oneOf: [
						{
							result_type: "inventory_products",
							queryString: {
								showArchived: "false",
							},
						},
					],
				},
			},
			inventory_product_output_ids: {
				type: "array",
				title: "Allowed Inventory Products for Outputs",
				title_translation_key: marker(
					"form_field_label_allowed_inventory_products_for_outputs",
				),
				widget: "data-select",
				items: {
					type: "number",
					oneOf: [
						{
							result_type: "inventory_products",
							queryString: {
								showArchived: "false",
							},
						},
					],
				},
			},
			inventory_product_destruction_ids: {
				type: "array",
				title: "Allowed Inventory Products for Destruction",
				title_translation_key: marker(
					"form_field_label_allowed_inventory_products_for_destruction",
				),
				widget: "data-select",
				items: {
					type: "number",
					oneOf: [
						{
							result_type: "inventory_products",
							queryString: {
								internal_vendor: "true",
								showArchived: "false",
							},
						},
					],
				},
			},
			output_inventory_status: {
				type: "boolean",
				widget: "checkbox",
				title: "Show Output Inventory Status",
				default: false,
				title_translation_key: marker("form_field_label_output_inventory_status"),
			},
			default_output_inventory_status_id: {
				type: "number",
				title: "Default Output Inventory Status",
				title_translation_key: marker(
					"form_field_label_default_output_inventory_status",
				),
				widget: "data-select",
				oneOf: [
					{
						result_type: "statuses",
						queryString: {
							status_type: StatusType.Inventory,
						},
					},
				],
				visibleIf: {
					output_inventory_status: [true],
				},
			},
			allow_plants_as_input: {
				type: "boolean",
				widget: "checkbox",
				title: "Plants allowed as work order input",
				title_translation_key: marker(
					"form_field_label_plants_allowed_as_work_order_input",
				),
				default: true,
			},
			allow_plants_as_output: {
				type: "boolean",
				widget: "checkbox",
				title: "Plants allowed as work order output",
				title_translation_key: marker(
					"form_field_label_plants_allowed_as_work_order_output",
				),
				default: true,
			},
			allow_plants_as_destruction: {
				type: "boolean",
				widget: "checkbox",
				title: "Plants allowed as work order destruction",
				title_translation_key: marker(
					"form_field_label_plants_allowed_as_work_order_destruction",
				),
				default: true,
			},
			section2: {
				type: "string",
				widget: "section",
				title: "Location Changes Related to Work Orders",
				title_translation_key: marker(
					"form_field_label_location_changes_related_to_work_orders",
				),
			},
			default_location_id: {
				type: "number",
				title: "Default work order location",
				title_translation_key: marker(
					"form_field_label_default_work_order_location",
				),
				widget: "data-select",
				oneOf: [
					{
						result_type: "locations",
					},
				],
			},
			allow_location_change: {
				type: "boolean",
				widget: "checkbox",
				title: "Allow default location to be changed by user.",
				title_translation_key: marker(
					"form_field_label_allow_default_location_to_be_changed_by_user",
				),
			},
			move_batch_to_location: {
				type: "boolean",
				widget: "checkbox",
				title: "Move batch to work order location",
				title_translation_key: marker(
					"form_field_label_move_batch_to_work_order_location",
				),
				description: `This will check the "Move batch to work order location" box by default on all inputs to the work order. This will only apply if a location is given for the work order.`,
				description_translation_key: marker(
					"form_field_description_this_will_check_the_move_batch_to_work_order_location_box_by_default",
				),
			},
			location_change_on_close: {
				type: "boolean",
				widget: "checkbox",
				title: "Force location change on close",
				title_translation_key: marker(
					"form_field_label_force_location_change_on_close",
				),
				description:
					"Require destination location for batch(es) when adding outputs to work order.",
				description_translation_key: marker(
					"form_field_description_require_destination_location_for_batches_when_adding_outputs_to_work_order",
				),
			},
		},
		required: ["name"],
	};

	validators = {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		"/name": (value, property, form) => {
			if (value !== "") {
				if (this.names && this.names.includes(value.trim())) {
					return { name: {} };
				}
			}
			return null;
		},
	};

	specific_field_id: string;

	generalInventoryEnabled$ = this._store.select(generalInventoryEnabled);
	gi_enabled = false;
	ezGiMigrated$ = this._store.select(ezGiMigration);
	ez_gi_migrated = false;

	private names;

	constructor(
		protected _store: Store<fromDashboard.State>,
		private readonly _globals: Globals,
		private readonly _itemService: ItemService,
		private readonly _injector: Injector,
		private readonly _devCycleService: DevCycleService,
		private readonly _translocoService: TranslocoService,
	) {
		super(_store);
		this.form_title = "Create a Work Order Type";
		this.form_title_translation_key = marker(
			"form_title_create_a_work_order_type",
		);
		this.submit_button = "Create";
		this.submit_button_translation_key = marker("word_create");

		this.specific_field_id = this._injector.get("specific_field_id", null);

		this._devCycleService
			.getVariable(DevCycleKey.WorkOrderOutputInventoryStatus, false)
			.subscribe((variable: any) => {
				if (!variable.value) {
					if (this.gi_enabled && !this.ez_gi_migrated) {
						delete this.schema.properties.output_inventory_status;
						delete this.schema.properties.default_output_inventory_status_id;
					}
				}
			});

		combineLatest([this.generalInventoryEnabled$, this.ezGiMigrated$]).subscribe(
			(values) => {
				this.gi_enabled = values[0];
				this.ez_gi_migrated = values[1];

				// Remove the section and multiselects for non gi, only gi allows user to specify
				// what types of inputs and outputs are allowed in work orders of this type
				if (!this.gi_enabled || this.ez_gi_migrated) {
					delete this.schema.properties.use_work_order_location_output;
					delete this.schema.properties.use_existing_inventory_output;
					delete this.schema.properties.use_existing_lot_output;

					delete this.schema.properties.processing_loss_inventory_product_ids;
					delete this.schema.properties.inventory_category_source_ids;
					delete this.schema.properties.inventory_category_output_ids;
					delete this.schema.properties.inventory_category_destruction_ids;
					delete this.schema.properties.inventory_product_source_ids;
					delete this.schema.properties.inventory_product_output_ids;
					delete this.schema.properties.inventory_product_destruction_ids;
					delete this.schema.properties.inventory_products_sources_outputs_section;
					delete this.schema.properties.output_inventory_status;
					delete this.schema.properties.default_output_inventory_status_id;
					delete this.schema.properties.require_lot_for_destruction;
				}
			},
		);

		if (this._globals.gmp_enabled) {
			delete this.schema.properties.duplicate_timestamp;
		}
	}

	ngOnInit() {
		// Get a list of all names currently in use.
		this.loading$.next(true);
		this._itemService
			.fetchItem(`work_order_types/fetch/names`, "", { col: "name" })
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((err) => {
					this.error$.next(handleObservableError(err));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.subscribe((value) => {
				// For duplicate name value validation
				this.names = value;
			});
		this.loading$.next(false);
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	createItem(work_order: Partial<IWorkOrderType>) {
		this.loading$.next(true);
		this._itemService
			.add(`work_order_types`, work_order)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((err) => {
					this.error$.next(handleObservableError(err, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((addedItem) => {
					this._store.dispatch(
						ItemActions.addSuccess({
							addedItem,
							result_type: "work_order_types",
							...(this.specific_field_id && {
								specific_field_id: this.specific_field_id,
							}),
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
